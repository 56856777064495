@tailwind base;
@tailwind components;
@tailwind utilities;

/*
w400
font-family: 'Lexend Deca', sans-serif;
*/
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

/*
w700
font-family: 'Big Shoulders Display', cursive;
*/
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@700&family=Red+Hat+Display:wght@400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lexend Deca', sans-serif;
  }